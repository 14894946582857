<template>
  <transition name="fade">
    <div id="detail" class="detail detail-show" v-show="show" @click.stop="">
      <div id="detail-meta" class="detail-meta">
        <h2 class="detail-title">
          <a :href="doc.url" v-html="doc.title" target="_blank"></a>
        </h2>
        <div class="detail-resource">{{ doc.info }}, <span class="detail-date">{{ doc.date }}</span></div
>
        <div v-if="doc.image_url" class="detail-image">
          <a :href="doc.url" target="_blank"><img :src="doc.image_url" onerror="this.remove();"></a>
        </div>
        <div class="detail-snippet" v-html="doc.content"></div>
      </div>

      <div id="relate" class="relate">
        <div class="relate-head">関連記事</div>
        <div class="relate-table">
          <div v-for="tab in tabList" class="relate-tab" :key="tab.title"
            :class="{ 'relate-tab-selected' : tab.selected }"
            @click="changeTab(tab.name)">{{ tab.title }}</div>
        </div>

        <div class="relate-article">
          <div v-show="loading" class="loader">Loading...</div>
          <ul>
            <li v-for="d in relatedList" :key="d.link">
              <a class="clearfix" :href="d.link" target="reader">
                <img v-if="d.img" :src="d.img" loading="lazy" onerror="this.remove();">
                <p class="article-title">{{ d.title }}</p>
                <p class="article-resource">{{ d.info }} <span class="article-date">{{ d.date }}</span></p>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="content-close" @click="contentClose">
        <span class="icon-close"></span>
      </div>

    </div>
  </transition>
</template>

<script>
import * as d3Fetch from 'd3-fetch'

const tabResource = {
  'watch': [
    { name: 'watch',     title: "Watch",    selected: false },
    { name: 'magagine',  title: "Magagine", selected: false },
    { name: 'iwp',       title: "白書",     selected: false },
    { name: 'jpubb',     title: "JPubb",    selected: false }
  ],
  'jpubb': [
    { name: 'jpubb',     title: "JPubb",    selected: false },
    { name: 'watch',     title: "Watch",    selected: false },
    { name: 'shinshomap', title: "新書",    selected: false }
  ],
  'shinshomap': [
    { name: 'shinshomap', title: "新書",    selected: false },
    { name: 'jpubb',      title: "JPubb",   selected: false },
    { name: 'watch',      title: "Watch",   selected: false }
  ],
  'timemap_iwp': [
    { name: 'timemap_iwp',       title: "白書",     selected: false },
    { name: 'magagine',  title: "Magagine", selected: false },
    { name: 'watch',     title: "Watch",    selected: false }
  ],
  'timemap_mag': [
    { name: 'timemap_mag',  title: "Magagine", selected: false },
    { name: 'iwp',       title: "白書",     selected: false },
    { name: 'watch',     title: "Watch",    selected: false }
  ],
  'iwanami_book': [],
  'iwanami_nempyo': [],
  'timemap_jiyugakuen': []
}

export default {
  props: ['show', 'docid', 'query', 'media'],
  data () {
    return {
      tabList: [],
      doc: {},
      relatedList: [],
      loading: true,
    }
  },
  watch: {
    docid () {
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      const query = {
        i: this.docid,
        q: this.query,
        r: this.media
      }
      const cgi = "./app/detail?" + this.params( query )
      d3Fetch.json(cgi).then( json => {
        this.doc = json
        document.getElementById("detail").scrollTop = 0
        this.tabList = tabResource[ this.media ]
        this.changeTab( this.media )
      })
    },

    changeTab( type ) {
      if ( this.tabList.length == 0 ) {
        this.loading = false
        return;
      }
      this.tabList.forEach( tab => {
        tab.selected = ( tab.name == type )
      })
      this.getRelatedList( type )
    },

    getRelatedList( type ) {
      this.loading = true
      this.relatedList = []
      const query = {
        //n: this.docid,
        n: this.doc.doc,
        q: this.query,
        s: this.media,
        t: type
      }
      const cgi = "./app/relate?" + this.params( query )
      d3Fetch.json(cgi).then( json => {
        this.relatedList = json.result
        this.loading = false
      })
    },

    contentClose() {
      this.$emit('detailClose')
    },

    params( obj ) {
      return Object.keys( obj ).map( (k) => {
            return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])
      }).join('&')
    },

  }
}
</script>

<style>
</style>

<style scoped>
.detail {
  position: fixed;
  top: 3.5rem;
  left: 105%;
  width: 20rem;
  height: calc(100% - 11.5rem);
  color: #000;
  background-color: #FFF;
  box-shadow: -1px 0px 7px rgba(0, 0, 0, 0.4);
  transition: left .5s;
  z-index: 130;
 }
.detail-show,
.settings-show {
  left: calc(100% - 20rem);
  overflow-y: auto;
}

.detail-meta {
  padding: 1rem 2.5rem 1rem 1rem;
}

.detail-title {
  font-size: 1.1rem;
  line-height: 120%;
  margin: 0 0 0.83em 0;
  font-weight: normal;
}
.detail-title:hover {
   text-decoration: underline;
}
.detail-title > a {
   color: #000;
 }
.detail-title > b,
.detail-snippet > b {
  color: #C44;
}

.detail-resource {
  width:100%;
  text-align: right;
  font-size: 0.95rem;
  margin: 1rem 0;
  border-bottom: 1px solid #888;
}
.detail-date,
.article-date {
   white-space: nowrap;
}
.detail-snippet {
  font-size: 0.9rem;
  line-height: 115%;
  margin-bottom: 1.5vh;
}

.relative-btn {
  display: none;
  position: fixed;
  top: 100px;
  left: 100px;
  border: 1px solid #999;
  border-radius: 10%;
  color: #999;
  background-color: #FFF;
}

.title-relative {
  display: none;
  fill: #999;
}

.relate {
  position: relative;
  min-height: 125vh;
}
.relate-head {
  padding: 0 2.5rem .5rem 1rem;
}

.relate-table {
  display: table;
  margin: 0 2.5rem 0 1rem;
  width: calc(100% - 3.5rem);
}

.relate-tab {
  display: table-cell;
  width: 25%;
  height: 1.5em;
  text-align: center;
  border: solid 1px #CCC;
  color: #888;
  background-color: #EEE;
}

.relate-tab:hover {
  cursor: pointer;
}

.relate-tab-selected {
  border-bottom: none;
  color: #333;
  background-color: #FFF;
}

.relate-article {
  /* min-height: 80vh; */
}

.relate-article ul {
  padding-top: .5rem;
}

.relate-article li {
}

.relate-article li:hover {
  background-color: #E8E8E8;
}


.relate-article img {
  float: left;
  width: 5rem;
  padding: 3px;
  border: solid 1px #CCC;
  margin-right: .75rem;
}

.relate-article a {
  display: block;
  margin: 0 2.5rem 0 1rem;
  padding: 1rem 0;
  text-decoration: none;
  border-bottom: 1px solid #BBB;
}

.article-title,
.article-resource {
  color: #000;
}

.article-title {
  margin: 0;
  line-height: 120%;
  font-size: 0.95rem;
}

.article-resource {
  margin: 0.5em 0 0 0;
  font-size: 0.9rem;
  text-align: right;
  line-height: 1.2rem;
}

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.detail-btn {
    position: absolute;
    display: inline-block;
    top: 0;
    right: 1vw;
    width: 40px;
    height: 40px;
    margin: 20px 0 10px;
    border: solid 1px transparent;
    border-radius: 50%;
    background: #fff;
}

.detail-btn:hover {
    cursor: pointer;
    border-color: #2196F3;
}

.content-close {
  position: fixed;
  top: 4rem;
  right: 0.8rem;
  color: #444;
  transition: all 0.3s 0s ease;
  font-size: 1.5rem;
}
.content-close:hover {
  cursor: pointer;
  transform: rotate(90deg);
}

.loader {
  position: absolute;
  top: 10rem;
  left: 50%;
  font-size: 0.75rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  text-indent: -9999rem;
  animation: load4 1.3s infinite linear;
  transform: translateZ(0) translate(-50%, 0);
}

.detail-image > a > img {
    float: left;
    width: 5rem;
    padding: 3px;
    border: solid 1px #CCC;
    margin-right: 0.75rem;
}

.fade-enter-active,
.fade-leave-active {
   transition: opacity .3s
 }
 .fade-enter,
 .fade-leave-to {
   opacity: 0
 }

</style>
