import * as d3 from 'd3'

class TIMEMAP {
  constructor() {
    this.svg = {}
    this.x = {}
    this.y = {}
    this.entries
    this.xAxis
    this.yAxis
    this.xAxis2
    this.yAxis2
    this.prev_scale = -1
    this.prev_idx = -1
    this.calc_rate
    this.height = 0
    this.width = 0
    this.start = 0
    this.end = 0
    this.scale_limit = 15
    this.max_scale = 0
    this.fontSize = 18
    this.total_num = 0
    this.backgray_width = 30
    this.wheel_delta
    this.max_point_total
    this.eventType = null
    this.zoom = {}
    this.naviLabel = []
    this.graphMode = 'heat'
  }

  init( fontSize ) {
    const self = this
    self.fontSize        = fontSize
    self.wheel_zoom      = self.detect_wheel_event() + ".zoom"
    self.box_width       = document.getElementById("map").clientWidth
    self.box_height      = document.getElementById("map").clientHeight
    self.navi_box_width  = document.getElementById("navi").clientWidth
    self.navi_box_height = document.getElementById("navi").clientHeight
  }

  operate_data(data_array, formObject) {
    const self = this
    self.formObject = formObject
    self.total_num  = formObject.total_num
    self.graphMode  = formObject.graphMode
    self.data = d3.merge(data_array)
    if ( self.data.length == 0 ) {
      // no search result
      self.removeGraph()
      formObject.loading = false
      return
    }
    // console.time('timer1')

    const margin = {
      top:    self.fontSize,
      right:  10,
      bottom: self.fontSize,
      left:   self.fontSize * 7
    }
    const navi_margin = {
      top:    self.navi_box_height * 0.19,
      right:  document.getElementsByClassName('sns')[0].clientWidth,
      bottom: margin.bottom,
      left:   self.fontSize * 10
    }
    const width  = self.box_width - margin.left - margin.right
    const height = self.box_height - margin.top - margin.bottom
    const navi_width = self.navi_box_width -
          navi_margin.left - navi_margin.right
    const navi_height = self.navi_box_height - navi_margin.top

    self.width = width
    self.height = height
    self.margin = margin
    self.navi_width = navi_width
    self.navi_height = navi_height
    self.navi_margin = navi_margin

    const x  = d3.scaleLinear().range([0, self.width])
    const y  = d3.scaleTime().range([height, 0])
    const yInit = d3.scaleTime().range([height, 0])

    const x2 = d3.scaleTime().range([0, self.navi_width])
    const y2 = d3.scaleLinear().range([0, self.navi_height])

    // self.color = d3.scaleOrdinal(d3.schemeCategory10)
    self.color =  ['rgb(79, 159, 212)', 'rgb(235, 121, 32)', 'rgb(56, 152, 62)']
    self.color2 = ['rgb(46, 123, 175)', 'rgb(239, 125, 29)', 'rgb(59, 175, 68)']

    self.xAxis = d3.axisBottom(x)
      .tickSizeInner(-height)  // 目盛線の長さ（内側）
      .tickPadding(10) // 目盛線とテキストの間の長さ

    self.yAxis = d3.axisLeft(y)
      .tickSizeInner(-width)
      .tickFormat(d3.timeFormat('%Y-%m-%d'))

    self.xAxis2 = d3.axisTop(x2)
      .tickPadding(0)
      .tickFormat(d3.timeFormat('%Y'))

    let tickValues = []
    if ( self.total_num == 1 ) {
      tickValues = [0.16]
    } else if ( self.total_num == 2 ) {
      tickValues = [0.31, 0.97]
    } else {
      tickValues = [0.48, 1.5, 2.55]
    }
    self.yAxis2 = d3.axisLeft(y2)
      .tickValues(tickValues)
      .tickFormat( (d, i) => {
        return ! self.naviLabel[ i ] || self.naviLabel[ i ] == 'NOQUERY' ? '' : self.naviLabel[ i ]
      })

    x.domain([0, self.total_num])
    y2.domain([0, self.total_num])

    const extent = d3.extent(self.data, d => d.epoch )
    if ( extent[1] == extent[0] ) {
      extent[1] += 86400000
      extent[0] -= 86400000
    }
    y.domain(extent)
    yInit.domain(y.domain())
    x2.domain(y.domain())

    const duration = x2.domain()[1].getYear() - x2.domain()[0].getYear()
    const every = Math.ceil( duration * self.fontSize * 3 / navi_width )
    self.xAxis2.ticks( d3.timeYear.every( every ) )

    self.x  = x
    self.y  = y
    self.yInit = yInit
    self.x2 = x2
    self.y2 = y2
    self.start = (self.y.domain())[0].getTime()
    self.end   = (self.y.domain())[1].getTime()

    self.calc_rate = self.make_calc_rate()

    // console.time('calc_space')

    // initialize data storage
    self.storage = d3.range(self.scale_limit).map( s => {
      return d3.range(Math.pow(2, s)).map( () => [] )
    })
    // calcurate each data points and max_scale
    const max_scales = data_array.map( (d, i) => {
      return self.calc_space(d, i)
    })

    self.max_scale = d3.max(max_scales)

    self.max_point_total = Math.log( d3.max( self.storage[0][0].map(
      d => d.point_size[0]
    ) ) )
    if ( self.max_point_total == 0 ) self.max_point_total = 1

    // console.timeEnd('calc_space')

    self.show_map()
    self.show_navi()

    self.entries.attr("transform", self.transform.bind(self))

    formObject.loading = false

    // console.timeEnd('timer1')
  }

  show_map() {
    const self = this

    d3.select("#map").selectAll("*").remove()

    self.svg = d3.select("#map").append("svg")
     .attr("class", "svg-map")
     .attr("width",  self.box_width)
     .attr("height", self.box_height)
     .on("wheel", () => {}) // in order to get wheel event for safari
     .append("g")
     .attr("transform", "translate(" + self.margin.left + "," + self.margin.top + ")")

    self.zoom = d3.zoom()
      .scaleExtent([1, Math.pow(2, self.max_scale+0.001)])
      .translateExtent([[0, 0], [self.width, self.height]])
      .extent([[0, 0], [self.width, self.height]])
      .on("zoom", event => self.zoomed(event))
      .clickDistance(10)

    self.svg.call(self.zoom)

    self.svg.append("rect")
      .attr("class", "rect zoom")
      .attr("width", self.width)
      .attr("height", self.height)
      // .on("mousedown touchstart", event => {
      //   d3.select(event.currentTarget).classed("rect-down", true)
      // })
      // .on("mouseup touchend", event => {
      //   d3.select(event.currentTarget).classed("rect-down", false)
      // })

    const line_contexts = d3.range(self.total_num).map( i => {
      const x = i * self.width / self.total_num + 19.5
      const context = d3.path()
      context.moveTo(x, -self.margin.top)
      context.lineTo(x, self.height + self.margin.top)
      return [context, self.color2[i]]
    })

    self.svg.selectAll(".dot-bg")
      .data(line_contexts)
      .enter()
      .append("path")
      .attr("class", "dot-bg")
      .attr("d", d => d[0].toString() )
      .attr("stroke", d => d[1] )

    self.gY = self.svg.append("g")
      .attr("class", "y axis axis--y")
      .style("font-size", self.fontSize + "px")
      .call(self.yAxis)

    // draw data
    self.update(0, true, 0)
  }

  show_navi( graphMode ) {
    const self = this
    self.graphMode = graphMode || self.graphMode

    const rectWidth = 6
    let splitNum = Math.floor( self.navi_width / rectWidth )
    if ( self.graphMode === "line" ) splitNum *= 0.5

    let data = [], key2epoch, maxValues
    if ( self.graphMode === "heat" || self.graphMode === "line" ) {
      const array = d3.merge( self.storage[ self.max_scale - 1 ] )
      const data_map = d3.group(array, d => d.x)
      data_map.forEach( (value, key) => {
        data[key] = d3.rollup(
          value,
          d => d.length,
          d => Math.floor( splitNum * ( d.epoch - self.start )
                                   / ( self.end - self.start ) )
        )
      })
      key2epoch = d3.range(splitNum+1).map(
        n => self.start + n * ( self.end - self.start ) / splitNum
      )
      maxValues = data.map( data2 => {
        return d3.max( data2.values() )
      })
    }

    d3.select("#navi").selectAll("*").remove()
    self.navi = d3.select("#navi").append("svg")
      .attr("class", "svg-navi")
      .attr("width", self.navi_box_width)
      .attr("height", self.navi_box_height)
      .on("wheel", () => {}) // in order to get wheel event for safari
      .append("g")
      .attr("transform", "translate(" + self.navi_margin.left + ',' + self.navi_margin.top + ")")
      .on(self.wheel_zoom, self.brush_scroll.bind(self))

    self.navi.append("rect")
      .classed("axis-top", true)
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", self.navi_box_width)
      .attr("height", self.navi_box_height * 0.17)
      .attr("transform", "translate(-" + self.navi_margin.left + ',-' + self.navi_margin.top + ")")

    const lineAry = [0.17, 0.445, 0.72, 1.0]
    lineAry.forEach( (h, i) => {
      const cls = i == 0 || i == 3 ? "axis-top-line" : "axis-mid-line"
      self.navi.append("line")
        .classed(cls, true)
        .attr("x1", 0)
        .attr("y1", self.navi_box_height * h)
        .attr("x2", self.navi_box_width)
        .attr("y2", self.navi_box_height * h)
        .attr("transform", "translate(-" + self.navi_margin.left + ',-' + self.navi_margin.top + ")")
    })
    for( let i=0; i<lineAry.length-1; i++ ) {
      const x = self.graphMode === 'line' ? -4 : 0
      self.navi.append("line")
        .classed('axis-vertical-line', true)
        .classed('line'+i, self.graphMode === 'line')
        .attr("x1", x)
        .attr("y1", (lineAry[i] + 0.04) * self.navi_box_height )
        .attr("x2", x)
        .attr("y2", (lineAry[i+1] - 0.04) * self.navi_box_height )
        .attr("transform", "translate(0,-" + self.navi_margin.top + ")")
    }

    //// graphMode
    if ( self.graphMode === undefined || self.graphMode === "heat" ) {
      data.forEach( (data2, i) => {
        const cls = "rect"+i
        const y = i
        self.navi.selectAll('.' . cls)
          .data( Array.from(data2.entries()) )
          .enter()
          .append("rect")
          .classed(cls, true)
          .attr("width", rectWidth - 2 )
          .attr("height", self.navi_height * 0.265)
          .attr("fill", d => {
            const hsl = d3.hsl( self.color[ y ] )
            hsl.l = hsl.l * 0.3 + hsl.l * 0.7 * d[1] / maxValues[i]
            return hsl.toString()
          })
          .attr("transform", d =>
            "translate(" + self.x2( key2epoch[d[0]] ) + "," +
                (self.navi_height * ( 0.34 * y + 0.015 )) + ")" )
      })
    } else if ( self.graphMode === "dot" ) {
      self.navi.selectAll("circle")
        .data(d3.merge(self.storage[ d3.min([self.max_scale - 1, 7])  ]))
        .enter()
        .append("circle")
        .attr("r", "1.5px")
        .attr("fill", d => self.color[d.x] )
        .attr("transform", d => "translate(" + self.x2(d.date) + ","
             + (self.navi_height * ( 0.34 * d.x + 0.25 * d.dx + 0.015)) + ")"
        )
    } else if ( self.graphMode === "line" ) {
      const maxV = d3.max( maxValues )
      const line = d3.line()
            .x( d => self.x2( key2epoch[d[0]] ) )
            .y( d => self.navi_height * ( 0.99 - d[1] / maxV ) )
      data.forEach( (data2, i) => {
        const y = i
        const data3 = []
        Array.from(data2.entries()).forEach( d => {
          data3[ d[0] ] = d
        })
        d3.range(splitNum).forEach( i => {
          if ( ! data3[ i ] ) {
            data3[i] = [i, 0]
          }
        })
        self.navi.append("path")
          .datum(data3)
          .classed("line"+y, true)
          .attr("d", line(data3))
      })
    }

    self.brushShadowLeft = self.navi.append("rect")
      .classed("brush-shadow", true)
      .attr("x", 0)
      .attr("y", self.navi_box_height * 0.17)
      .attr("width", self.navi_box_width)
      .attr("height", self.navi_box_height)
      .attr("transform", "translate(0" + ',-' + self.navi_margin.top + ")")

    self.brushShadowRight = self.navi.append("rect")
      .classed("brush-shadow", true)
      .attr("x", 0)
      .attr("y", self.navi_box_height * 0.17)
      .attr("width", self.navi_box_width)
      .attr("height", self.navi_box_height)
      .attr("transform", "translate(0" + ',-' + self.navi_margin.top + ")")

    self.brush = d3.brushX()
      .extent([[0, 0], [self.navi_width, self.navi_height]])
      .handleSize(1)
      .on("brush", event => self.brushed(event))

    self.navi.append("g")
      .attr("class", "x axis axis--x2")
      .attr("transform", "translate(0," + 0 + ")")
      .style("font-size", self.fontSize - 1 + "px")
      .call(self.xAxis2)

    self.navi.append("g")
      .attr("class", "y axis axis--y2")
      .attr("transform", "translate(0," + 0 + ")")
      .style("font-size", self.fontSize - 1 + "px")
      .call(self.yAxis2)

    self.navi.append("g")
      .attr("class", "brush")
      .call(self.brush)
      .call(self.brush.move, self.x2.range())
      .selectAll(".overlay")
      .each( d => { d.type = "selection" }) // Treat overlay interaction as move.
      .on("mousedown touchstart", self.brush_click.bind(self)) // Recenter before brushing.
  }

  update(scale, is_change_scale, storage_idx) {
    const self = this
    if ( ! self.storage[scale] ) return
    const data = d3.merge(self.storage[scale]
                        .slice(Math.max(0, storage_idx-1),
                               Math.min(self.storage[scale].length,
                                        storage_idx+2)))
    // console.log("scale:" + scale + " storage idx:" + storage_idx + " data num:" + data.length)

    // DATA JOIN: Join new data with old elements.
    const entries = self.svg.selectAll(".entry")
          .data( data, d => d.aid )

    // ENTER: Create new elements.
    const new_entries = entries
          .enter().append("g")
          .classed("entry", true)
          .call(self.append_text.bind(self))
          .on("click", (event, d) => {
            event.stopPropagation()
            self.entry_click(event.currentTarget, d, true)
          })

    new_entries.append("circle")
      .classed("dot", true)
      .on("click", (event, d) => {
        event.stopPropagation()
        self.circle_click(event, d)
      })
      .attr("cx", 20)
      .attr("r",            d => Math.max(  4, 14 * Math.log(d.point_size[scale]) / self.max_point_total) )
      .attr("fill-opacity", d => Math.max(0.4, 1  - Math.log(d.point_size[scale]) / self.max_point_total) )
      .attr("stroke-width", d => d.point_size[scale] == 1 ? 3 : 0 )
      .attr("fill",         d => self.color2[d.x] )

    // EXIT: Remove old elements.
    entries.exit().remove()

    // UPDATE: Dots can change only if the scale changes
    if ( is_change_scale ) {
      entries.selectAll(".dot")
        .attr("r",            d => Math.max(  4, 14 * Math.log(d.point_size[scale]) / self.max_point_total) )
        .attr("fill-opacity", d => Math.max(0.4, 1  - Math.log(d.point_size[scale]) / self.max_point_total) )
        .attr("stroke-width", d => d.point_size[scale] == 1 ? 3 : 0 )
    }

    // Re-select
    self.entries = self.svg.selectAll(".entry")
  }

  entry_click( entry, d ) {
    const self = this
    d3.select(".selected-entry").classed("selected-entry", false)
    d3.select(entry).classed("selected-entry", true)
    self.formObject.toggleDetail( d )
  }

  circle_click( event, d ) {
    const self = this
    if ( d.point_size[self.prev_scale] > 1 ) {
      const diff = event.shiftKey ? -1 : 1
      self.rescale(null, diff, ( 1 - d.rate ) )
    }
  }

  append_text( entries ) {
    const self = this
    entries.each(function() {
      const entry = d3.select(this)
      const data = this.__data__
      if ( data.text_node ) {
        entry.node().appendChild( data.text_node )
        if ( data.category )
          entry.node().appendChild( data.category_node )
      } else {
        const offset = data.category ? 6.25 * self.fontSize : 0
        const text_node = entry.append("text")
          .attr("dy", ".35em")
          .attr("dx", (self.backgray_width + 5 + offset) + "px")
          .attr("font-size", self.fontSize)

        let category_node
        if ( data.category ) {
          category_node = entry.append("text")
            .attr("dy", ".35em")
            .attr("dx", (self.backgray_width + 5) + "px")
            // .classed("category", true)
            .text(data.category)
            .attr("font-size", self.fontSize)
        }

        const titleB = 'title-b' + data.x
        data.title.split(/〓/).forEach( (w, i) => {
          if ( w == "" ) return
          text_node.append("a").classed(titleB, i%2).text(w)
        })
        // cache text DOM to data property
        data.text_node = text_node.node()
        if ( data.category )
          data.category_node = category_node.node()
      }
    })
  }

  transform(d) {
    return "translate(" + this.x(d.x) + "," + this.y(d.date) + ")"
  }

  zoomed(event) {
    const self = this
    // console.log("zoomed: this.eventType)
    if ( event.sourceEvent &&
         ( event.sourceEvent.type === 'wheel' ||
           event.sourceEvent.type === 'mousemove' ||
           event.sourceEvent.type === 'touchmove' ) ) {
      self.eventType = 'zoomed'
    }
    if ( self.eventType !== 'brushed' ) {
      self.eventType = 'zoomed'
      const domain = event.transform.rescaleY(self.yInit).domain()
      self.y.domain( domain )
      self.gY.call( self.yAxis )

      self.navi.select(".brush")
        .call(self.brush.move, domain.map(self.x2))

      const s = self.get_selection()
      self.brushShadowLeft.attr("width", s[0] + 0.1)
      self.brushShadowRight.attr("width", self.navi_width - s[1] + 0.1)
                           .attr("x", s[1])
    }

    // console.time('zoom_timer')

    const s = self.get_selection()
    const scale = self.get_scale2( s )
    const int_log_scale = Math.floor( Math.log( scale ) / Math.LN2 )
    const rate = 1 - 0.5 * (s[0] + s[1]) / self.navi_width
    const idx = Math.floor(rate * Math.pow(2, int_log_scale))

    const is_change_scale = int_log_scale != self.prev_scale
    const is_change_idx   = idx != self.prev_idx
    if ( is_change_scale || is_change_idx )
      self.update(int_log_scale, is_change_scale, idx)

    self.entries.attr("transform", self.transform.bind(self))

    // console.timeEnd('zoom_timer')

    self.prev_scale = int_log_scale
    self.prev_idx = idx
  }

  brushed(event) {
    const self = this
    const sourceEvent = event.sourceEvent ? event.sourceEvent.type : null
    const s = event ? event.selection : self.get_selection()
    // console.log("brushed:", this.eventType, sourceEvent)
    const domain = s.map(self.x2.invert, self.x2)
    self.y.domain( domain )
    self.gY.call(self.yAxis)
    const to_y = domain.map(self.yInit)
    const scale = self.get_scale2(s)

    if ( self.eventType !== 'zoomed' || sourceEvent == 'mousemove' ) {
      self.eventType = 'brushed'
      self.svg.call(
        self.zoom.transform,
        d3.zoomIdentity.translate(0, -scale*to_y[1]).scale(scale)
      )
    }
    self.brushShadowLeft.attr("width", s[0] + 0.1)
    self.brushShadowRight.attr("width", self.navi_width - s[1] + 0.1)
                         .attr("x", s[1])
  }

  rescale(to_scale, diff, center_rate) {
    const self = this
    const brush_length = self.navi_width
    const s = self.get_selection()

    if (to_scale == null) {
      const from_scale = self.get_scale2(s) + 0.001
      const scale = Math.log(from_scale)/Math.LN2 + diff
      to_scale = diff > 0 ? Math.floor(scale) + 0.001 : Math.ceil(scale-0.002)
    }
    to_scale = Math.min(self.max_scale + 0.001, Math.max(0, to_scale))

    let extent = []
    const dx = brush_length / Math.pow(2, to_scale)
    if ( center_rate ) {
      const center = brush_length * center_rate
      extent[0] = center - ( center - s[0] ) * dx / (s[1] - s[0])
      extent[1] = extent[0] + dx
    } else {
      const center2 = 0.5 * (s[1] + s[0])
      extent = [center2 - 0.5*dx, center2 + 0.5*dx]
    }
    if ( extent[0] < 0 ) {
      extent = [0, dx]
    } else if ( extent[1] > brush_length ) {
      extent = [brush_length - dx, brush_length]
    }
    self.brush_transition(extent)
  }

  brush_transition(extent) {
    this.eventType = "brushed"
    this.navi.select(".brush")
      .transition()
      .duration(350)
      .call(this.brush.move, extent)
  }

  brush_click(event) {
    const self = this
    const center = event.x - self.navi_margin.left
    if ( isNaN(center) || center < 0 )
      return
    const s = self.get_selection()
    const dx = s[1] - s[0]
    const x0 = center - 0.5 * dx
    const x1 = center + 0.5 * dx
    const extent = x1 > self.navi_width ?
          [self.navi_width - dx, self.navi_width] :
          (x0 < 0 ? [0, dx] : [x0, x1])
    self.brush_transition(extent)
    event.stopPropagation()
  }

  brush_scroll( event ) {
    event.preventDefault()
    const self = this
    const delta = event.delta // check!
    const deltaX = delta || -1 * self.wheel_delta(event)
    if ( deltaX === undefined ) return
    const s = self.get_selection()
    const scale = self.get_scale2(s)
    const log_s = Math.log(scale)/Math.LN2

    // log_s=0,1,2 -> 0.002;    2 * Math.pow(10, -3 - 0)
    // log_s=3,4,5 -> 0.0002;   2 * Math.pow(10, -3 - 1)
    // log_s=6,7,8 -> 0.00002;  2 * Math.pow(10, -3 - 2)
    // log_s=9,10  -> 0.000002; 2 * Math.pow(10, -3 - 3)
    const dist = -1 * self.navi_width * deltaX
        * 2 * Math.pow(10, -3 - ((log_s-1) / 3))
    const dx = s[1] - s[0]
    let extent = [s[0] + dist, s[1] + dist]
    if ( extent[0] < 0 ) extent = [0, dx]
    if ( extent[1] > self.navi_width ) extent = [self.navi_width - dx, self.navi_width]
    self.eventType = "brushed"
    self.navi.select(".brush").call(self.brush.move, extent)
  }

  make_calc_rate() {
    const self = this
    const end_time = self.end
    const range = self.end - self.start
    return epoch => {
      return (end_time - epoch) / range
    }
  }

  calc_rate2(selection) {
    return 1 - 0.5*(selection[0]+selection[1]) / this.navi_width
  }

  get_scale2(selection) {
    return this.navi_width / (selection[1] - selection[0])
  }

  get_selection() {
    return d3.brushSelection( this.navi.select(".brush").node() )
  }

  calc_space(data, x) {
    const self = this
    // const slide_time = self.contents[x].slide_time || 864e5
    const slide_time = 864e5

    // Count the same days : Map
    const overlap = d3.rollup(data, leaves => leaves.length, d => d.epoch)

    const overlap_slide = {}
    const overlap_count = {}
    Array.from(overlap).forEach( ([key, value]) => {
      overlap_slide[key] = slide_time / value
      overlap_count[key] = value
    })

    const geta = /〓/g
    const alpha_re = /[A-Z0-9'",.?$*()+-]/gi
    const title_len = Math.floor(((self.width / self.total_num)
                                - self.backgray_width) / self.fontSize) - 1

    let data2 = data.map( d => {
      const d2 = {
        aid:      d.aid + "-" + x,
        category: d.category,
        rank:     d.rank,
        url:      d.url,
        epoch:    d.epoch,
        x:        x,
        dx:       Math.random(),
        rate:     0,
        point_size: [],
        date:     {},
        title:    '',
        text_node: null,
        category_node: null
      }
      // overlap slide
      // if ( overlap[d.epoch] > 1 ) {
      //   overlap[d.epoch]--
      //   d2.epoch += overlap[d.epoch] * overlap_slide[d.epoch]
      // }
      // 要修正 2023/06/06
      if ( overlap.get(d.epoch) >= 1 ) {
        const count_down = overlap.get(d.epoch)
        overlap.set(d.epoch, count_down - 1)
        d2.epoch += count_down * overlap_slide[d.epoch]
        // d2.epoch += (overlap_count[d.epoch] - count_down - 1) * overlap_slide[d.epoch]
      }

      d2.rate = self.calc_rate(d2.epoch)
      d2.date = new Date(d2.epoch)

      d.title = d.title.replace(/::BR::/g, ' ')
      const ary = d.title.substr(0, title_len).match(alpha_re)
      let t_len = ary ? title_len + ary.length / 2 : title_len
      if ( d.category ) t_len -= 6
      const geta_ary = d.title.substr(0, t_len).match(geta)
      if ( geta_ary ) t_len += geta_ary.length
      d2.title = d.title.substr(0, t_len) +
        (d.title.length > t_len ? "..." : "")

      return d2
    })

    // sort by hit rank
    data2 = data2.sort( (a, b) => {
      return d3.ascending(a.rank, b.rank)
    })

    let max_scale = self.scale_limit
    let all_filled = false
    d3.range(self.scale_limit).forEach( s => {
      const split_num = Math.pow(2, s)
      const height = self.height * split_num
      const storage = self.storage[s]
      const space = []
      const hide_d = []
      const show_d = []
      data2.forEach( d => {
        if ( ! all_filled ) {
          const y = Math.floor(height * d.rate)
          for(let i=0; i<=self.fontSize; ++i) {
            if ( space[y-i] ) {
              space[y-i][s]++
              hide_d.push(d)
              return
            } else if ( space[y+i] ) {
              space[y+i][s]++
              hide_d.push(d)
              return
            }
          }
          // ここまで来たdはspace[y]で表示される。以降はその下に隠れるdの数が加算される
          space[y] = d.point_size
        }
        d.point_size[s] = 1
        show_d.push(d)
        let bin = Math.floor(d.rate * split_num)
        if ( ! storage[bin] ) bin--
        if ( storage[bin] ) storage[bin].push(d)
      })
      data2 = show_d.concat( hide_d )
      if ( hide_d.length == 0 && ! all_filled ) {
        all_filled = true
        max_scale = s
      }
    })

    if ( max_scale == 0 ) max_scale = 1
    return max_scale
  }

  detect_wheel_event() {
    const self = this
    const wheel =
      "onwheel" in document ?
      (self.wheel_delta = event => {
        return -event.deltaY * (event.deltaMode ? 120 : 1)
      }, "wheel") :
      "onmousewheel" in document ?
      (self.wheel_delta = event => {
        return event.wheelDelta
      }, "mousewheel") :
      (self.wheel_delta = event => {
        return -event.detail
      }, "MozMousePixelScroll")

    return wheel
  }

  setNaviLabel( label ) {
    this.naviLabel = label
  }
  setFontSize( fontSize ) {
    this.fontSize = fontSize
  }

  removeGraph() {
    d3.select("#map").selectAll("*").remove()
    d3.select("#navi").selectAll("*").remove()
  }
}

export default new TIMEMAP()
