<template>
  <div class='navi-wrap'>
    <div id='navi' class='navi'></div>
  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  }
}
</script>

<style>
.navi-wrap {
  position: absolute;
  bottom: 1.5rem;
  height: 6.5rem;
  width: 100vw;
}
.navi {
  height: 100%;
  overflow: hidden;
  background-color: #000;
}
.axis--y2 line {
  display: none
}
.axis--y2 text {
  fill: rgb( 203, 203, 203 );
}
.axis--x2 text {
  fill: rgb( 204, 204, 204 );
}

.axis-top {
  fill: rgb( 66, 66, 78 );
}
.axis-top-line {
  stroke: rgb( 78, 77, 99 );
  stroke-width: 2;
}
.axis-mid-line {
  stroke: rgb( 77, 77, 77 );
  stroke-width: 2;
}
.axis-vertical-line {
  stroke: rgb( 63, 68, 71 );
  stroke-width: 2;
}

.brush .selection {
  stroke: none;
  fill: #FFF;
  fill-opacity: 0;
  shape-rendering: crispEdges;
  clip-path: url(#clip);
}

.brush .selection.navi-hover {
  fill-opacity: .7;
}

.handle {
  stroke: #FFF;
  stroke-width: 1.5px;
  width: 1px;
}

.dot-bg {
  stroke-width: 1;
}

.line0,
.line1,
.line2 {
  fill: none;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.line0 {
  stroke: rgb(127, 184, 248);
}
.line1 {
  stroke: rgb(209, 128, 44);
}
.line2 {
  stroke: rgb(99, 149, 65);
}
.axis-vertical-line.line0,
.axis-vertical-line.line1,
.axis-vertical-line.line2 {
  stroke-width: 4;
}

.brush-shadow {
  fill: #000;
  fill-opacity: 0.5;
}
</style>

<style scoped>
</style>
