<template>
  <div>
    <div class="top" v-if="topShow" @click="bodyClick()">
      <div class='header'>
        <h1><img src="img/timemap.svg" alt="timemap"></h1>
      </div>
      <div class="middle">
        <div class="input-wrap">
          <input type="text" class="query" v-model.trim.lazy="query">
          <span class="mapping" @click="search()"><img src="img/icon_mapping.svg">MAPPING</span>
        </div>
      </div>
      <div class="navi-area">
        <div class="graph">
          <iframe src="./embed/?r=watch&c=internet_watch&q0=Internet%20Explorer&q1=Netscape%20%7C%20Firefox&q2=Chrome&g=dot&t=%E6%BF%80%E3%81%97%E3%81%8B%E3%81%A3%E3%81%9F%E3%82%A6%E3%82%A7%E3%83%96%E3%83%96%E3%83%A9%E3%82%A6%E3%82%B6%E3%83%BC%E6%88%A6%E4%BA%89" style="border:none; width: 100%; height:10rem;"></iframe>
        </div>
        <div class="graph">
          <iframe src="./embed/?r=watch&c=internet_watch&q0=Yahoo&q1=%22goo%22&q2=Google&g=heat&t=%E3%83%9D%E3%83%BC%E3%82%BF%E3%83%AB%E3%82%B5%E3%82%A4%E3%83%88%E3%81%8B%E3%82%89%E6%A4%9C%E7%B4%A2%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%B8%E3%81%AE%E5%A4%89%E5%8C%96" style="border:none; width: 100%; height:10rem; overflow: hidden;"></iframe>
        </div>
        <div class="graph">
          <iframe src="./embed/?r=watch&q0=%22Web%202.0%22%20%7C%20%22Web2.0%22&q1=%22Web%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%22&q2=API&g=line&t=%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E5%8C%96%E3%81%8C%E9%80%B2%E3%82%80%E3%82%A6%E3%82%A7%E3%83%96" style="border:none; width: 100%; height:10rem"></iframe>
        </div>
        <div class="graph">
          <iframe src="./embed/?r=jpubb&q0=%E4%BA%BA%E5%B7%A5%E7%9F%A5%E8%83%BD&q1=IoT&q2=VR%20%7C%20AR&g=dot&t=%E5%90%84%E7%94%A3%E6%A5%AD%E3%81%8C%E6%B3%A8%E7%9B%AE%E3%81%99%E3%82%8BAI%E3%83%BBIoT%E3%83%BBVR%2FAR%E3%81%AE%E5%BF%9C%E7%94%A8" style="border:none; width: 100%; height:10rem"></iframe>
        </div>
        <div class="graph">
          <iframe src="./embed/?r=shinshomap&q0=%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%BC%E3%83%8D%E3%83%83%E3%83%88&q1=%E8%91%97%E4%BD%9C%E6%A8%A9%20%7C%20%E7%9F%A5%E7%9A%84%E8%B2%A1%E7%94%A3%E6%A8%A9%20%7C%20%E7%89%B9%E8%A8%B1&q2=%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%82%B9%E3%82%BF%E3%83%B3%E3%83%80%E3%83%BC%E3%83%89%20%7C%20%E4%B8%96%E7%95%8C%E6%A8%99%E6%BA%96%20%7C%20%E5%9B%BD%E9%9A%9B%E6%A8%99%E6%BA%96&g=heat&t=%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%BC%E3%83%8D%E3%83%83%E3%83%88%E3%81%AE%E6%99%AE%E5%8F%8A%E3%81%A8%E6%B1%82%E3%82%81%E3%82%89%E3%82%8C%E3%82%8B%E7%A4%BE%E4%BC%9A%E7%9A%84%E6%95%99%E9%A4%8A" style="border:none; width: 100%; height:10rem"></iframe>
        </div>
        <div class="graph">
          <iframe src="./embed/?r=watch&q0=ISDN&q1=DSL%20%7C%20ADSL&q2=%E5%85%89%E3%83%95%E3%82%A1%E3%82%A4%E3%83%90&g=line&t=%E6%80%A5%E9%80%9F%E3%81%AB%E9%80%B2%E3%82%93%E3%81%A0%E3%83%96%E3%83%AD%E3%83%BC%E3%83%89%E3%83%90%E3%83%B3%E3%83%89%E5%8C%96%E3%81%AE%E9%81%93%E7%AD%8B" style="border:none; width: 100%; height:10rem"></iframe>
        </div>
      </div>
      <div class="footer">&copy; TIMEMAP</div>

      <div class='header-settings' title="メニュー"
        :class="{ opened: settingsShow }"
        @click.stop="toggleSettings()">
        <span class="icon-settings"></span>
      </div>

      <menu-component v-bind:show="settingsShow"></menu-component>

    </div>

    <form-component v-if="topShow == false"></form-component>
  </div>
</template>

<script>
import formComponent from './components/form.vue'
import menuComponent from './components/menu.vue'
import '@/assets/style.css' // for icon fonts

export default {
  data () {
    return {
      query: '',
      topShow: false,
      settingsShow: false
    }
  },
  components: {
    formComponent,
    menuComponent
  },
  watch: {
    query: function() {
      this.search()
    },
    '$route' (to) {
      // console.log(to)
      this.topShow = Boolean( to.fullPath == './' )
    }
  },
  mounted () {
    this.topShow = ! ( this.$route.query.q0 || this.$route.query.q1 || this.$route.query.q2 || this.$route.query.q )
  },
  methods: {
    search() {
      const self = this
      if ( self.query == "" ) return
      self.$router.push({ path: "./", query: { r: 'watch', q0: self.query }})
      self.topShow = false
    },
    bodyClick() {
      const self = this
      self.settingsShow = false
    },
    toggleSettings() {
      const self = this
      self.settingsShow = ! self.settingsShow
    }

  }
}
</script>

<style>
</style>

<style scoped>
 .header {
   position: relative;
   background-color: #000;
   height: 22vh;
   width: 100%;
 }
 .header > h1 {
   position: absolute;
   margin: 0;
   height: 7.5vh;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
 }
 .header > h1 > img {
   height: 100%;
 }
 .header-settings {
   position: fixed;
   top: 0;
   right: 0;
   padding: .5rem;
   font-size: 1.2rem;
   line-height: 1.2rem;
   transition: all 0.3s 0s ease;
   border-left: solid 2px rgb(77, 77, 77);
   background-color: #000;
 }
 .header-settings:hover {
   cursor: pointer;
   background-color: rgb(80, 80, 80);
 }
 .header-settings.opened {
   color: #000;
   background-color: #FFF;
   border-color: #FFF;
 }
 .header .icon-settings {
   display: inline-block;
   vertical-align: middle;
 }
 .top .menu {
   top: 2.2em;
 }
 .middle {
   position: relative;
   height: 22vh;
   width: 100%;
 }
 .input-wrap {
   position: absolute;
   top: 50%;
   transform: translate(0, -50%);
   width: 100%;
   text-align: center;
 }
 .query {
   border: 1px solid #000;
   border-radius: .5rem;
   font-size: 1rem;
   padding: .75rem;
   width: 18rem;
   vertical-align: middle;
   margin-right: .5rem;
   outline: none;
 }
 .query:focus {
   border-width: 2px;
 }
 .mapping {
   color: #FFF;
   background-color: #000;
   padding: .9rem;
   font-size: 1rem;
   border-radius: .5rem;
   vertical-align: middle;
 }
 .mapping:hover {
   cursor: pointer;
 }
 .mapping > img {
   height: 1.25rem;
   vertical-align: middle;
   margin-right: .5rem;
   transition: all 0.3s 0s ease;
 }
 .mapping:hover > img {
   transform: rotate(90deg);
 }
 .navi-area {
   display: flex;
   width: 100%;
   min-height: calc(100vh - 44vh - 1.5rem);
   background-color: #000;
   padding: 2rem;
   box-sizing: border-box;
   flex-wrap: wrap;
   justify-content: center;
 }
 .graph {
   width: 25rem;
   margin: .5rem;
   overflow:auto;
   -webkit-overflow-scrolling:touch;
     display: inline-block;
 }
 .footer {
   width: 100%;
   height: 1.5rem;
   line-height: calc(1.5rem - 1px);
   text-align: center;
   border-top: 1px solid #555;
   color: #555;
   background-color: #000;
   box-sizing: border-box;
 }

 @media (max-width: 512px) {
   .query {
     width: 8rem;
   }
   .navi-area {
     padding: 2rem 1rem;
   }
 }


</style>
