<template>
  <div class="container2" @click="bodyClick()">
    <div class='header'>
      <div class='header-left'>
        <h1><a href="./"><img src="img/timemap.svg" alt="timemap"></a></h1>
        <div v-if="m == 'contents'">
          <input type="text" class="query-r" v-model.trim.lazy="q[0]">
        </div>
        <div v-if="m == 'queries'" class="select-r-wrap" @click.stop="toggleMediaSelect(0, $event)">
          <div class="select-r">{{ selectedTitle[0] }}</div>
          <div class="select-r-icon icon-gear"></div>
        </div>

      </div>
      <div class='header-right'>
        <div class='header-compare-word' title="単語による比較"
          :class="{'header-compare-selected': (m == 'queries')}"
          @click="changeMode('queries')">
          <span class="icon-icon_words"></span>単語による比較
        </div>
        <div class='header-compare-media' title="メディアによる比較"
          :class="{'header-compare-selected': (m == 'contents')}"
          @click="changeMode('contents')">
          <span class="icon-icon_media"></span>メディアによる比較
        </div>
        <div class='header-font' title="文字サイズの変更"
          :class="{ opened: fontShow }"
          @click.stop="toggleFont()">
          <span class="icon-fontsize"></span>
        </div>
        <div class='header-settings' title="メニュー"
          :class="{ opened: settingsShow }"
          @click.stop="toggleSettings()">
          <span class="icon-settings"></span>
        </div>
      </div>
    </div>

    <form id="form-keyword" v-show="m == 'queries'" class="form-wrap form-keyword">
      <div class="form-buttons" :style="{marginLeft: formButtonsLeft}">
        <div>
          <input type="text" class="q0" v-model.trim.lazy="q[0]" :style="{width: formButtonWidth}">
        </div>
        <div>
          <input type="text" class="q1" v-model.trim.lazy="q[1]" :style="{width: formButtonWidth}">
        </div>
        <div>
          <input type="text" class="q2" v-model.trim.lazy="q[2]" :style="{width: formButtonWidth2}">
        </div>
        <a class="mapping2" @click="search('form')"><img src="img/icon_mapping2.svg"></a>
      </div>
    </form>

    <form id="form-media" v-show="m == 'contents'" class='form-wrap form-media'>
      <div class="form-buttons" :style="{marginLeft: formButtonsLeft}">
        <div @click.stop="toggleMediaSelect(0, $event)">
          <div class="s0" :style="{width: formButtonWidth3}">{{ selectedTitle[0] }}</div>
          <div class="select-r2-icon icon-gear"></div>
        </div>
        <div @click.stop="toggleMediaSelect(1, $event)">
          <div class="s1" :style="{width: formButtonWidth3}">{{ selectedTitle[1] }}</div>
          <div class="select-r2-icon icon-gear"></div>
        </div>
        <div @click.stop="toggleMediaSelect(2, $event)">
          <div class="s2" :style="{width: formButtonWidth3}">{{ selectedTitle[2] }}</div>
          <div class="select-r2-icon icon-gear"></div>
        </div>
      </div>
    </form>

    <map-component></map-component>

    <navi-component></navi-component>

    <div class="footer">
      <div class="footer-navi" :style="{marginLeft: fontSize * 10 + 'px'}">
        <span class="graph-icon icon-dot_graph"
          title="ドット表示"
          :class="{'graph-selected': (graphMode == 'dot')}"
          @click="changeGraph('dot')"></span>
        <span class="graph-icon icon-line_graph"
          title="ライン表示"
          :class="{'graph-selected': (graphMode == 'line')}"
          @click="changeGraph('line')"></span>
        <span class="graph-icon icon-density_graph"
          title="濃度バー表示"
          :class="{'graph-selected': (graphMode == 'heat')}"
          @click="changeGraph('heat')"></span>
      </div>
      <div class="copyright">&copy; TIMEMAP</div>
    </div>

    <div class="sns">
      <a :href="twitterURL" class="twitter icon-twitter" title="Twitter"></a><a :href="facebookURL" class="facebook icon-facebook" title="Facebook"></a><a class="share icon-share2" @click.stop="toggleEmbed" title="共有する"></a>
    </div>

    <media-component
      ref="media"
      v-bind:show="mediaSelectShow"
      v-bind:mediaSet="currentMediaSet"
      v-on:mediaSelectClose="toggleMediaSelect"
      :class="[mediaClass]">
    </media-component>

    <detail-component
      v-bind:show="detailShow"
      v-bind:docid="detailDocId"
      v-bind:query="detailQuery"
      v-bind:media="detailMedia"
      v-on:detailClose="toggleDetail"
      :class="[detailClass]">
    </detail-component>

    <transition name="fade">
      <div class="resize-font" v-show="fontShow" @click.stop="">
        文字サイズ
        <input id="font-range" v-model="fontSize" class="font-range" type="range" min="8" max="25">
        <span class="font-size">{{ fontSize }}</span>
      </div>
    </transition>

    <menu-component v-bind:show="settingsShow"></menu-component>

    <transition name="fade">
      <div class="embed-dialog" v-show="embedShow" @click.stop="">
        <p class="embed-desc">作成したTIMEMAPをブログで共有できます。以下のHTMLコードを貼り付けてください。</p>
        <div>
          <input type="text" v-model="embedTitle" class="embed-title" placeholder="タイトル (任意)">
        </div>
        <p class="embed-code">{{ embedCode }}</p>
        <div class="embed-close" @click.stop="toggleEmbed">
          <span class="icon-close"></span>
        </div>
      </div>
    </transition>

    <div v-show="loading" class="loader">Loading...</div>

  </div>
</template>

<script>
import * as d3Fetch from 'd3-fetch'
import TIMEMAP from '../modules/timemap'
import mapComponent from './map.vue'
import naviComponent from './navi.vue'
import mediaComponent from './media.vue'
import detailComponent from './detail.vue'
import menuComponent from './menu.vue'
import media from '../modules/media.json'

const mediaString = JSON.stringify(media)
const mediaSet = [
  JSON.parse( mediaString ),
  JSON.parse( mediaString ),
  JSON.parse( mediaString )
]
mediaSet[1][0].selected = false
mediaSet[1][1].selected = true
mediaSet[2][0].selected = false
mediaSet[2][2].selected = true
let url_cache = {}

let fontTimer, resizeTimer

export default {
  data () {
    return {
      m: 'queries',
      s: mediaSet,
      q: [],
      total_num: 0,
      fontSize: localStorage.getItem("fontSize") || 16,
      mediaSelectShow: false,
      detailShow: false,
      fontShow: false,
      settingsShow: false,
      embedShow: false,
      currentMediaSet: mediaSet[0],
      detailDocId: '',
      detailQuery: '',
      detailMedia: '',
      naviLabel: [],
      graphMode: localStorage.getItem("graphMode") || 'heat',
      loading: false,
      queryOrder: '0',
      mediaClass: 'media0',
      notHistory: false,
      embedTitle: '',
      embedCode: '',
      twitterURL: '',
      facebookURL: '',
    }
  },
  components: {
    mapComponent,
    naviComponent,
    mediaComponent,
    detailComponent,
    menuComponent
  },
  watch: {
    $route () {
      // $route (to, from) {
      // console.log("change url", to, from)
      if ( this.notHistory )
        return
      this.parseUrlQuery()
      this.search('watch $route')
    },
    q () {
      this.search('watch q')
    },
    fontSize () {
      if ( fontTimer ) {
        clearTimeout( fontTimer )
      }
      fontTimer = setTimeout( () => {
        localStorage.setItem("fontSize", this.fontSize)
        TIMEMAP.setFontSize( this.fontSize )
        this.search('watch fontSize')
      }, 500)
    },
    embedTitle () {
      this.embedCode = this.makeEmbedCode()
    },
  },
  computed: {
    selectedMedia () {
      return this.s.map( (media) => {
        return media.find( (medium) => {
          return medium.selected
        })
      })
    },
    selectedTitle () {
      if (! Array.isArray(this.s)) return ''
      return this.s.map( (media) => {
        const m = media.find( (medium) => {
          return medium.selected
        })
        if (! m) return ''
        const noCheck = m.children.find( child => {
          return child.checked == false
        })
        if ( noCheck ) {
          const checkedTitles = m.children.filter( child => {
            return child.checked == true
          }).map( child => {
            return child.title
          })
          return m.title + "【" + checkedTitles.join(", ") + "】"
        } else {
          return m.title
        }
      })
    },
    detailClass () {
      return 'query-order' + this.queryOrder
    },
    formButtonsLeft () {
      return this.fontSize * 8 + 'px'
    },
    formButtonWidth () {
      return `calc(-.3rem + (100vw - ${this.fontSize * 8}px) / 3)`
    },
    formButtonWidth2 () {
      return `calc(-3.8rem + (100vw - ${this.fontSize * 8}px) / 3)`
    },
    formButtonWidth3 () {
      return `calc(-.45rem + (100vw - ${this.fontSize * 8}px) / 3)`
    },
  },
  mounted() {
    TIMEMAP.init( this.fontSize )
    this.parseUrlQuery()
    window.addEventListener( 'resize', () => {
      if ( resizeTimer ) {
        clearTimeout( resizeTimer )
      }
      resizeTimer = setTimeout( () => {
        this.resize()
      }, 750)
    })
  },
  methods: {
    search(state) {
      // console.log("search:", state)
      this.loading = true
      const urls = this.build_query(state)
      if ( this.total_num == 0 ) {
        this.loading = false
        TIMEMAP.removeGraph()
        return
      }
      const promises = urls.map( url => {
        return this.get_data(url)
      })
      Promise.all( promises ).then(
        values => {
          TIMEMAP.setNaviLabel(this.naviLabel)
          TIMEMAP.operate_data(values, this)
          this.notHistory = false
        },
        rejected => {
          console.log(rejected)
          TIMEMAP.removeGraph()
          this.loading = false
          alert('エラーが発生しました。')
          this.notHistory = false
          return []
        }
      )
    },

    parseUrlQuery() {
      const query = this.$route.query
      if ( query.r || query.m == 'queries') {
        this.m = 'queries'
      } else if ( query.q || query.m == 'contents' ) {
        this.m = 'contents'
      }
      if ( query.q0 ) {
        this.m = 'queries'
        if ( ! query.r ) {
          query.r = 'watch'
        }
      }
      if ( this.m == 'queries' ) {
        for( let i=0; i<3; i++ ) {
          this.q.push( query['q' + i] || '' )
        }
        this.s[0].forEach( medium => {
          medium.selected = Boolean( medium.name == query.r )
        })
        if ( query.c ) {
          let checked = {}
          query.c.split(',').forEach( m => {
            checked[m] = true
          })
          this.s[0].find( medium => {
            return medium.selected
          }).children.forEach( (child) => {
            child.checked = Boolean( checked[ child.name ] )
          })
        }
      } else if ( this.m == 'contents' ) {
        this.q = [query.q || query.q0, query.q1 || '', query.q2 || '']
        for( let i=0; i<3; i++ ) {
          if ( query['r' + i] ) {
            this.s[i].forEach( medium => {
              medium.selected = Boolean( medium.name == query['r' + i] )
            })
          }
          if ( query['c' + i] ) {
            let checked = {}
            query['c' + i].split(',').forEach( m => {
              checked[m] = true
            })
            this.s[i].find( medium => {
              return medium.selected
            }).children.forEach( (child) => {
              child.checked = Boolean( checked[ child.name ] )
            })
          }
        }
      }
    },

    build_query(state) {
      const urls = []
      const num = 3
      const urlParams = {}
      this.total_num = 0
      this.naviLabel = []
      // const cgi = './timemap_search.cgi'
      const cgi = './app/search'
      if ( this.m === "queries" ) {
        const r = this.selectedMedia[0].name
        urlParams['r'] = r
        const categories = this.makeCategories( this.selectedMedia[0] )
        let c
        if ( categories.length ) {
          c = categories.join(',')
          urlParams['c'] = c
        }
        for( let i=0; i<num; i++ ) {
          let q = this.q[i] || "NOQUERY"
          this.naviLabel[i] = q
          const p = {
            q: q,
            r: r
          }
          if ( c ) p.c = c
          if ( q !== "NOQUERY" ) {
            this.total_num = i+1
            urlParams['q' + i] = p.q
          }
          urls.push( cgi + '?' + this.params(p) )
        }
      } else if ( this.m === "contents" ) {
        this.total_num = 0
        const q = this.q[0]
        if ( ! q ) {
          this.total_num = 0
          return
        }
        urlParams['q'] = q
        for(let i=0; i<num; i++) {
          const media = this.selectedMedia[i]
          if ( media.name != 'no_select' )
            this.total_num++
          const r = media.name
          this.naviLabel[i] = this.makeNaviTitle( media )
          const categories = this.makeCategories( media )
          let p = {
            q: q,
            r: r
          }
          if ( media.showAll ) {
            p.a = "1"
            urlParams['a' + i] = p.a
          }
          if ( categories.length ) {
            p.c = categories.join(',')
            urlParams['c' + i] = p.c
          }
          urlParams['r' + i] = p.r
          urls.push( cgi + '?' + this.params(p) )
        }
      }
      if ( state !== 'init' ) {
        if ( ! equal(urlParams, this.$route.query) ) {
          this.notHistory = true
          this.$router.push({ path: "/", query: urlParams })
        }
      }
      this.makeSNSLink()

      return urls
    },

    get_data( url ) {
      if ( url_cache[url] !== undefined ) {
        return url_cache[url]
      } else {
        return d3Fetch.tsv(url, this.transType)
                      .then( data => {
                        url_cache[url] = data
                        return data
                      })
      }
    },

    makeSNSLink() {
      let title = this.q.filter( s => Boolean(s !== '') ).join(', ')
      let link = location.origin + location.pathname + '?'
               + this.params( this.$route.query )
      this.twitterURL = 'https://twitter.com/share?url=' + link + '&text=TIMEMAP:' + title
      this.facebookURL = 'https://www.facebook.com/sharer.php?u=' + link

      this.changeMeta( title, link )
    },

    changeMeta( title, link ) {
      const metaNodes = document.head.children
      for(let i = 0; i < metaNodes.length; i++){
        const property = metaNodes[i].getAttribute('property')
        if ( property === 'og:title' ) {
          metaNodes[i].setAttribute( 'content', 'TIMEMAP: ' + title )
        } else if ( property === 'og:url' ) {
          metaNodes[i].setAttribute( 'content', link )
        } else if ( property === 'og:image' ) {
          const url = location.origin + './screenshot/?p='
                    + encodeURIComponent( this.params( this.$route.query ) + '&g=heat' )
          metaNodes[i].setAttribute( 'content', url )
        }
      }
    },

    makeCategories( medium ) {
      const noCheck = medium.children.find( child => {
        return child.checked == false
      })
      if ( noCheck ) {
        return medium.children.filter( child => {
          return child.checked == true
        }).map( child => {
          return child.name
        })
      } else {
        return []
      }
    },

    makeNaviTitle( media ) {
      const noCheck = media.children.find( child => {
        return child.checked == false
      })
      if ( noCheck ) {
        const checkedTitles = media.children.filter( child => {
          return child.checked == true
        }).map( child => {
          return child.title
        })
        return checkedTitles.join(", ")
      } else {
        return media.title
      }
    },

    bodyClick() {
      if ( this.mediaSelectShow )
        this.toggleMediaSelect()
      if ( this.detailShow ) {
        const selectedEntry = document.getElementsByClassName('selected-entry')
        if ( selectedEntry.length ) selectedEntry[0].classList.remove('selected-entry')
        this.detailShow = false
      }
      this.fontShow = false
      this.settingsShow = false
      this.embedShow = false
    },

    toggleMediaSelect( idx, event ) {
      if ( idx !== undefined ) {
        this.currentMediaSet = this.s[idx]
      }
      if ( ! this.mediaSelectShow ) {
        const rect = event.target.getBoundingClientRect()
        document.getElementById("select-content").style.top = 'calc(1.5vh + ' + (rect.top + rect.height) + 'px)'
        this.mediaClass = 'media' + (this.m === "contents" ? idx : 99)
      } else {
        setTimeout( () => {
          this.search('toggleMediaSelect')
        }, 40)
      }
      this.embedShow = false
      this.mediaSelectShow = ! this.mediaSelectShow

    },

    toggleDetail( d ) {
      this.embedShow = false
      if ( d ) {
        const aidMedia = d.aid.split('-')
        if ( aidMedia[0] == '0' ) {
          this.getMore( aidMedia[1], d.url )
          return
        }
        this.queryOrder = aidMedia[1]
        const queryIdx = this.m == 'queries' ? this.queryOrder : '0'
        const selectedMediaIdx =  this.m == 'queries' ? '0' : this.queryOrder
        if ( aidMedia[0] == this.detailDocId ) {
          this.detailShow = ! this.detailShow
          document.getElementsByClassName('selected-entry')[0].classList.remove('selected-entry')
          return
        }
        this.detailQuery = this.q[ queryIdx ]
        this.detailMedia = this.selectedMedia[ selectedMediaIdx ].name
        this.detailDocId = aidMedia[0]
        if ( this.detailShow ) return
      }
      this.detailShow = ! this.detailShow
    },

    toggleFont() {
      this.settingsShow = false
      this.fontShow = ! this.fontShow
    },

    toggleSettings() {
      this.fontShow = false
      this.settingsShow = ! this.settingsShow
    },

    toggleEmbed() {
      if ( ! this.embedShow ) {
        this.embedCode = this.makeEmbedCode()
      }
      this.embedShow = ! this.embedShow
    },

    changeMode( mode ) {
      this.m = mode
      if ( ! this.q[0] ) {
        this.q[0] = this.q[1] || this.q[2]
      }
      this.search('changeMode')
    },

    changeGraph( mode ) {
      this.graphMode = mode
      localStorage.setItem("graphMode", mode)
      TIMEMAP.show_navi( mode )
    },

    params( obj ) {
      return Object.keys( obj ).map( (k) => {
            return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])
      }).join('&')
    },

    makeEmbedCode() {
      let url = location.origin + location.pathname + 'embed/?' + this.params( this.$route.query )
      url += '&g=' + this.graphMode
      if ( this.embedTitle )
        url += '&t=' + encodeURIComponent( this.embedTitle )
      return '<iframe src="' + url + '" style="border:none; width: 100%; height:10rem"></iframe>';
    },

    resize() {
      if ( ! document.getElementById("map") )
        return
      TIMEMAP.init( this.fontSize )
      this.search('resize')
    },

    getMore( mediaOrder, offset ) {
      const urls = this.build_query()
      this.loading = true
      d3Fetch.tsv( urls[mediaOrder] + '&o=' + offset, this.transType )
             .then(
               data => {
                 const key = urls[mediaOrder]
                 url_cache[key].pop()
                 url_cache[key] = url_cache[key].concat( data )
                 this.search('getMore')
               },
               rejected => {
                 console.log(rejected)
                 this.loading = false
                 alert('エラーが発生しました。')
               }
             )
    },

    transType(d) {
      d.epoch = +d.epoch * 1000
      d.title = d.title.replace(/\\"/g, '"')
      return d
    },
  }
}

const equal = (a, b) => {
  const aJSON = JSON.stringify(Object.entries(a).sort())
  const bJSON = JSON.stringify(Object.entries(b).sort())
  return aJSON === bJSON
}
</script>

<style>
body {
    margin: 0;
    position: relative;
    box-sizing: border-box;
    color: #FFF;
}

a,
a:hover,
a:active,
a:visited {
  color: #FFF;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

.container,
.container2 {
  width: 100%;
  height: 100vh;
}

.query-order0 b {
  color: rgb(46, 123, 175);
}
.query-order1 b {
  color: rgb(239, 125, 29);
}
.query-order2 b {
  color: rgb(59, 175, 68);
}

.query-order0 .loader {
  color: rgb(165, 212, 243);
}
.query-order1 .loader {
  color: rgb(255, 206, 166);
}
.query-order2 .loader {
  color: rgb(170, 229, 173);
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s
}
.fade-enter,
.fade-leave-to {
  opacity: 0
}
</style>

<style scoped>
.header {
  background-color: #000;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  overflow: hidden;
}

.form-wrap {
  position: absolute;
  top: 3.5rem;
  height: 3.5rem;
  width: 100vw;
  box-shadow: 0 .25vh 1vh rgba(0, 0, 0, .5);
  z-index: 100;
  background-color: #FFF;
  box-sizing: border-box;
}

.footer {
  position: absolute;
  height: 1.5rem;
  width: 100vw;
  bottom: 0;
  background-color: rgb(66, 66, 78);
  display: flex;
  justify-content: space-between;
  line-height: 1.5rem;
}

.header-left,
.header-right {
  display: flex;
}
.header h1 {
  margin: 0 2vw 0 2vw;
  height: 2.5rem;
}

.header h1 a {
}

.header h1 img {
  height: 2rem;
  vertical-align: top;
  margin: .75rem 0;
}

.header-right {
  color: #FFF;
  text-align: right;
}

.header-compare-word,
.header-compare-media,
.header-font,
.header-settings {
  padding: 0 1vw;
  border-top: solid 0.35rem transparent;
  border-bottom: solid 0.35rem transparent;
  line-height: 2.8rem;
  height: 3.5rem;
  box-sizing: border-box;
  transition: all 0.3s 0s ease;
}

.header-compare-word:hover,
.header-compare-media:hover,
.header-font:hover,
.header-settings:hover {
  cursor: pointer;
  background-color: rgb(80, 80, 80);
}

.header-compare-word,
.header-compare-media {
  margin: 0 2vw;
  font-weight: bold;
}

.header-compare-selected {
  border-bottom-color: rgb(209, 209, 209);
}

.header-font,
.header-settings {
  border-left: solid 2px rgb(77, 77, 77);
}

.header .icon-icon_media,
.header .icon-icon_words,
.header .icon-fontsize,
.header .icon-settings {
  display: inline-block;
  vertical-align: middle;
}
.header .icon-icon_media,
.header .icon-icon_words {
  margin-right: .5rem;
  font-size: 1.5rem;
}
.header .icon-icon_media {
  /* border-left: solid 1px #FFF; */
}

.header .icon-fontsize {
  font-size: 1.7rem;
}
.header .icon-settings {
  font-size: 1.2rem;
}

.header-font.opened,
.header-settings.opened {
  color: #000;
  background-color: #FFF;
}

.form-media,
.form-keyword {
}

.mapping2 {
  transition: all 0.3s 0s ease;
}
.mapping2:hover {
  cursor: pointer;
  transform: rotate(90deg);
}
.mapping2 > img {
  height: 2rem;
  margin: 0.75rem 0;
  vertical-align: middle;
}

.form-buttons {
  display: flex;
  margin-left: 10vw;
}

.form-buttons > div {
  position: relative;
  margin: 0.75rem 0.5rem 0.75rem 0;
}

.q0,
.q1,
.q2,
.s0,
.s1,
.s2 {
  border: solid 1px #FFF;
  border-radius: 0.25rem;
  height: 2rem;
  padding: 0 1vw;
  width: 26vw;
  line-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  box-sizing: border-box;
  font-size: 1rem;
  box-shadow: 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, .2) inset
}

.q0,
.s0 {
  background-color: rgb(165, 212, 243);
  border-color: rgb(46, 123, 175);
}

.q1,
.s1 {
  background-color: rgb(255, 206, 166);
  border-color: rgb(239, 125, 29);
}

.q2,
.s2 {
  background-color: rgb(170, 229, 173);
  border-color: rgb(59, 175, 68);
}

.s0, .s1, .s2 {
  color: #000;
  padding-right: 2rem;
  user-select: none;
}
.s0:hover, .s1:hover, .s2:hover {
  cursor: pointer;
}

.query-r,
.select-r {
  width: 20vw;
  min-width: 10rem;
  height: 2rem;
  border-radius: 0.2rem;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.95rem;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.query-r {
  margin: .75rem 0;
  padding: .2rem .6rem .2rem .5rem;
  color: #000;
}
.select-r-wrap {
  position: relative;
  margin: .75rem 0;
}
.select-r {
  display: inline-block;
  color: #FFF;
  background-color: rgb(74, 74, 74);
  padding: .2rem 2rem .2rem .6rem;
}
.select-r-icon {
  position: absolute;
  font-size: 2rem;
  top: 0;
  right: 0;
  transition: all 0.3s 0s ease;
}
.select-r-wrap:hover {
  cursor: pointer;
}

.select-r2-icon {
  display: block;
  position: absolute;
  top: 0.125rem;
  right: 0.125rem;
  font-size: 1.75rem;
  vertical-align: middle;
  transition: all 0.3s 0s ease;
  cursor: pointer;
}

.select-r:hover + .select-r-icon {
  transform: rotate(90deg);
}

.s0:hover + .select-r2-icon,
.s1:hover + .select-r2-icon,
.s2:hover + .select-r2-icon {
  transform: rotate(90deg);
}

.footer-navi {
  margin-left: 6rem;
}
.graph-icon {
  margin-right: .2rem;
  transition: all 0.3s 0s ease;
  color: rgb(143, 144, 145);
}
.graph-icon:hover {
  color: #FFF;
  cursor: pointer;
}
.graph-selected {
  color: #FFF;
}
.copyright {
  color: rgb(153, 153, 153);
  padding-right: .5rem;
  font-size: .9rem;
}

.resize-font {
  position: fixed;
  top: 3.5rem;
  right: 2rem;
  z-index: 201;
  background-color: rgb(235, 235, 235);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  color: #000;
  padding: 1rem;
}
.font-range {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 1.0);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2) inset;
  border-radius:24px;
  width: 12rem;
  outline: none;
  vertical-align: middle;
}

.font-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #008ee0;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  cursor:pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.font-range {
  margin-bottom: 2px;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  color: rgb(165, 212, 243);
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  text-indent: -9999rem;
  animation: load4 1.3s infinite linear;
  transform: translateZ(0) translate(-50%, -50%);
}

.sns {
  position: fixed;
  right: 0;
  padding: 0 .5rem;
  bottom: 3.5rem;
  width: 6rem;
}

.twitter,
.facebook,
.google-plus,
.share {
  display: inline-block;
  border-radius: 50%;
  color: #FFF;
  font-size: .8rem;
  margin-left: .3rem;
  padding: .35rem;
  vertical-align: middle;
}
.twitter:hover,
.facebook:hover,
.google-plus:hover,
.share:hover {
  cursor: pointer;
}
.twitter {
  background-color: rgb(81, 172, 228);
}
.facebook {
  background-color: rgb(50, 91, 145);
}
.google-plus {
  background-color: rgb(201, 86, 69);
  font-size: 1rem;
  padding: .32rem;
}
.share {
  background-color: rgb(142, 188, 99);
  padding: .35rem .42rem .35rem .28rem;
}

.embed-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22rem;
  z-index: 202;
  background-color: #FFF;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  color: #000;
  overflow: auto;
  max-height: 80vh;
}

.embed-desc,
.embed-title,
.embed-code {
  padding: 1em;
}
.embed-desc {
  margin: 0;
  padding-right: 2rem;
  color: #FFF;
  background-color: #000;
}
.embed-title {
  width: 20rem;
  margin: .5rem 1rem;
  box-sizing: border-box;
  outline: none;
}
.embed-title:focus {
  border: solid 2px #333;
}
.embed-code {
  margin: 0 0 1rem 0;
  word-break: break-all;
}
.embed-close {
  position: absolute;
  top: .5rem;
  right: .5rem;
  color: #FFF;
  transition: all 0.3s 0s ease;
}
.embed-close:hover {
  cursor: pointer;
  transform: rotate(90deg);
}

@media (max-width: 1024px) {
  .sns {
    bottom: 2.75rem;
    width: 4rem;
  }
}

@media (max-width: 512px) {
  .form-buttons {
    margin-left: 3vw;
  }
  .sns {
    bottom: 2rem;
    width: 2rem;
  }
}

</style>
