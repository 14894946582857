<template>
  <div class="map-wrap">
    <div id='map' class='map'></div>
  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },
}
</script>

<style>
.map-wrap {
  position: absolute;
  top: 7rem;
  height: calc(100% - 15rem);
  width: 100vw;
  background-color: #FFF;
}
.map {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
}
.axis {
  fill: #888;
}
.axis line {
  stroke: #000;
  shape-rendering: crispEdges;
}
.axis path {
  fill: none;
  stroke: none;
}
.x.axis path {
  display: none;
}

.map .axis path,
.map .axis line {
  fill: none;
  shape-rendering: crispEdges;
}

.dot-bg {
  stroke-width: 1.5;
}
.svg-map .rect {
  fill: #fff;
}
.dot {
  stroke: #FFF;
}

.dot:hover {
  fill-opacity: 1;
  cursor: pointer;
}

.entry > text {
  fill: #555;
  user-select: none;
}

.entry:hover > text {
  text-decoration: underline;
  cursor: pointer;
}

.selected-entry > text {
  font-weight: bold;
  fill: #111;
}

.title-b {
  fill: #C44;
}
.title-b0 {
  fill: rgb(46, 123, 175);
}
.title-b1 {
  fill: rgb(239, 125, 29);
}
.title-b2 {
  fill: rgb(59, 175, 68);
}
.category-b {
  fill: #378CB5;
}

.scale-hide {
  display: none;
}

.tick text {
  user-select: none;
  fill: #665;
}

.tick line {
  opacity: 0.2;
}
</style>

<style scoped>
</style>
