import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import VueGtag from "vue-gtag"

Vue.config.productionTip = false
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes: [
  ]
})

if ( process.env.VUE_APP_GA_ID ) {
  Vue.use(VueGtag, {
    config: {id: process.env.VUE_APP_GA_ID}
  }, router);
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
