<template>
  <transition name="fade">
    <div class="menu" v-show="show">
      <ul>
        <li><a href="https://timemap.info/about-timemap">TIMEMAPとは</a></li>
        <li><a href="https://timemap.info/about-us">About us</a></li>
        <li><a href="https://timemap.info/content-partner">コンテンツパートナー</a></li>
        <li><a href="https://timemap.info/terms">利用規約</a></li>
        <li><a href="https://timemap.info/help">ヘルプ</a></li>
        <li><a href="https://timemap.info/today/">今日のTIMEMAP</a></li>
        <li><a href="https://timemap.info/news">お知らせ</a></li>
        <li><a href="https://timemap.info/contact">お問い合わせ</a></li>
      </ul>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['show'],
}
</script>

<style>
</style>

<style scoped>
.menu {
  position: fixed;
  top: 3.5rem;
  right: 0;
  background-color: rgb(235, 235, 235);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  z-index: 200;

}
.menu li {
}
.menu li:not(:first-child) {
  border-top: solid 1px rgb(205, 205, 205);
}
.menu a {
  display: block;
  padding: .5rem 1.5rem;
  color: #000;
}
.menu a:hover {
  background-color: #FFF;
}
</style>
