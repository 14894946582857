<template>
  <transition name="fade">
    <div id="select-content" class="select-content" v-show="show" @click.stop="">
      <div class="content-wrap">

        <div class="content-left">
          <div class="content-left-inner">
            <div class="content-header">メディアを選択</div>
            <ul>
              <li v-for="medium in mediaSet" :key="medium.name">
                <label>
                  <input type="radio" :value="medium.name" v-model="mediaRadio">
                  <span>{{ medium.title }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="content-right">
          <div class="content-header">カテゴリを選択</div>
          <ul class="content-child">
            <li v-for="child in current.children" :key="child.title">
              <label>
                <input type="checkbox" v-model="child.checked">
                <span>{{ child.title }}</span>
              </label>
            </li>
          </ul>
          <div class="content-checkall">
            <label v-if="current.children && current.children.length" @click="checkAll( true )">
              <input type="checkbox" checked="checked" disabled>
              <span>すべてチェックする</span>
            </label>
            <label v-if="current.children && current.children.length" @click="checkAll( false )">
              <input type="checkbox" disabled>
              <span>すべてのチェックを外す</span>
            </label>
            <label v-if="current.isShowAll">
              <input type="checkbox" v-model="current.showAll" @click="showAll()">
              <span>全件表示</span>
            </label>
          </div>
        </div>

      </div>

      <div class="content-close" @click="mediaSelectClose"><span class="icon-close"></span></div>
    </div>
  </transition>
</template>

<script>

export default {
  props: ['show', 'mediaSet'],
  data () {
    return {
      mediaRadio: 'watch',
      current: {}
    }
  },
  watch: {
    show () {
      this.current = this.mediaSet.find( (medium) => {
        return medium.selected
      })
      this.mediaRadio = this.current.name
    },
    mediaRadio () {
      this.mediaSet.forEach( (medium) => {
        medium.selected = ( medium.name == this.mediaRadio )
        if ( medium.selected ) {
          this.current = medium
        }
      })
    },
    mediaSet () {
      const medium = this.mediaSet.find( (medium) => {
        return medium.selected
      })
      this.current = medium
      this.mediaRadio = medium.name
    }
  },
  methods: {
    mediaSelectClose() {
      this.$emit('mediaSelectClose')
    },
    checkAll( flag ) {
      this.current.children.forEach( (child) => {
        child.checked = flag
      })
    },
    showAll() {
      this.current.showAll = ! this.current.showAll;
    }
  }
}
</script>

<style>
</style>

<style scoped>
.select-content {
  position: absolute;
  left: 0;
  width: 100vw;
  color: #000;
  background-color: #FFF;
  box-shadow: 0 .25vh 1vh rgba(0, 0, 0, .5);
  z-index: 110;
}

.select-content::before {
  border-style: solid;
  border-width: 0 1.5vh 1.5vh;
  border-color: #FFF transparent;
  content: " ";
  top: -1.5vh;
  left: 30vw;
  position: absolute;
  background-color: transparent;
}

.media0.select-content::before {
  border-color: rgb(165, 212, 243) transparent;
  left: 23vw;
}
.media1.select-content::before {
  border-color: rgb(255, 206, 166) transparent;
  left: 50vw;
}
.media2.select-content::before {
  border-color: rgb(170, 229, 173) transparent;
  left: 77vw;
}

.media0 .content-wrap {
  border-top: solid .25rem rgb(165, 212, 243)
}
.media1 .content-wrap {
  border-top: solid .25rem rgb(255, 206, 166)
}
.media2 .content-wrap {
  border-top: solid .25rem rgb(170, 229, 173)
}

.content-wrap,
.content-option {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-left,
.content-right {
}

.content-left {
  width: 25vw;
  padding: 1vh 3vw;
  text-align: right;
}
.content-right {
  width: 75vw;
  padding: 1vh 6vw 1vh 3vw;
}

.content-right {
  position: relative;
  background-color: rgb(231, 232, 233);
  padding-bottom: 3vh;
}

.content-right::before {
  border-style: solid;
  border-width: 1.5vh 1.5vh 1.5vh 0 ;
  border-color: transparent rgb(231, 232, 233) transparent transparent;
  content: " ";
  top: 7.5vh;
  left: -1.4vh;
  position: absolute;
  background-color: transparent;
}
.content-left-inner {
  display: inline-block;
  text-align: left;
}
.content-left li,
.content-right li {
  margin-bottom: .25rem;
}
.content-right li {
  display: inline-block;
  width: 11rem;
}

.content-header {
  color: rgb(50, 51, 52);
  padding-bottom: 0.2em;
  margin-bottom: 1em;
  width: 100%;
  border-bottom: solid 1px rgb(152, 153, 154);
}

.content-checkall {
  margin-top: 1.5vh;
}
.content-checkall label {
  color: #FFF;
  background-color: #000;
  border-radius: 0.25em;
  padding: 0.3em;
  margin-right: .5rem;
  cursor: pointer;
}

.content-close {
  position: absolute;
  top: 1vh;
  right: 2vw;
  font-size: 1.5rem;
  color: rgb(50, 51, 52);
  transition: all 0.3s 0s ease;
}
.content-close:hover {
  cursor: pointer;
  transform: rotate(90deg);
}
</style>
